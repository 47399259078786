/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com


* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useLocation, useParams } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

function GoHighLevel() {
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const { companyId, app_id, locationId } = useParams()

  const auth_url = `https://zoom.us/oauth/authorize?response_type=code&client_id=0SHhxpBySfWviaAHkuu1lA&redirect_uri=https://app.alltheapps.io/integrations/zoom&state=${locationId},${companyId},${app_id},${type}`

  const handleConnect = (e) => {
    e.preventDefault()
    window.open(auth_url, '_blank')
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '35%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom style={{ color: 'black' }}>
          Connect with Zoom
        </MDTypography>

        <MDBox component="form" onSubmit={handleConnect} sx={{ mt: 15 }}>
          <MDButton
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
            style={{ fontSize: '0.9rem', textTransform: 'capitalize', padding: '0 5rem' }}
          >
            Connect
          </MDButton>
        </MDBox>
      </Card>
    </div>
  )
}

export default GoHighLevel
