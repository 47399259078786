/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Authentication from 'layouts/authentication'
// import Admin from 'layouts/admin'
// import Agency from 'layouts/agency'
import Integration from 'layouts/integration'

import { useUserInfo } from 'context/user'
import { useBrandInfo } from 'context/brand'
import NotFound from 'layouts/not-found'
import GotoWebinar from 'layouts/oauth/gotowebinar'
// import { Roles } from 'enums/user'
import Calender from 'layouts/oauth/calenders'
import Scratch from 'layouts/oauth/scratchCard'
import AgencyApp from 'layouts/oauth/agencyapp'
import Acquity from 'layouts/oauth/acquity'

import Servicem8trigger from 'layouts/oauth/servicem8trigger'

import Chargebee from 'layouts/oauth/chargebee'
import Simpro from 'layouts/oauth/simpro'
import Zoom from 'layouts/oauth/zoom'
import DrChrono from 'layouts/oauth/drchrono'

import ClickupTriger from 'layouts/oauth/clickuptrigger'

import Trello from 'layouts/oauth/trello'
import Copper from 'layouts/oauth/copper'
import Green from 'layouts/oauth/green'
import HotSlot from 'layouts/oauth/hotslots'

import Woocommerce from 'layouts/oauth/woocommerce'
import Mux from 'layouts/oauth/mux'
import Mailjet from './layouts/oauth/mailjet'
import Stripe from 'layouts/oauth/stripe'

import StripeCustomMenuLink from 'layouts/oauth/stripeCustomMenuLink'
import MessagebirdCustomMenuLink from 'layouts/oauth/messagebirdCustomMenuLink'

import Messagebird from 'layouts/oauth/messagebird'

import Raindrop from 'layouts/oauth/raindrop'

import Spreadsheets from 'layouts/oauth/spreadsheet'
import PdfGenerator from 'layouts/oauth/pdfgenerator'

import Deeplinks from 'layouts/pages/deeplinks'
import Sharelink from 'layouts/pages/sharelinks'
import QRCode from 'layouts/pages/qrcode'
import WinLooseDraw from 'layouts/oauth/winloosedraw'
import UploadCsv from 'layouts/oauth/uploadcsv'
import JobNimbus from 'layouts/oauth/jobnimbus'

import WinLooseCustomMenuLink from 'layouts/oauth/winlooseCustomMenuLink'

const useRoutes = () => {
  const [user] = useUserInfo()
  const [brand] = useBrandInfo()

  console.log(brand)

  return [
    {
      type: 'collapse',
      key: 'not_found',
      route: '/404',
      component: <NotFound />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/gotowebinar/:locationId/:companyId',
      component: <GotoWebinar />,
    },
    {
      type: 'collapse',
      key: 'uploadcsv',
      route: '/uploadcsv/:locationId/:companyId',
      component: <UploadCsv />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/app/:appId/:company_id/:locationId',
      component: <Stripe />,
    },
    {
      type: 'collapse',
      key: 'stripe_custom',
      route: '/app-custom-menu-link/:companyId/:app_id',
      component: <StripeCustomMenuLink />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/messagebird',
      component: <Messagebird />,
    },
    {
      type: 'collapse',
      key: 'messagebird_custom',
      route: '/messagebird-custom-menu-link/:locationId/:companyId',
      component: <MessagebirdCustomMenuLink />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/winloosedraw/app/:appId/:company_id/:locationId',
      component: <WinLooseDraw />,
    },
    {
      type: 'collapse',
      key: 'jobnimbus',
      route: '/jobnimbus/:locationId/:app_id/:company_id',
      component: <JobNimbus />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/winloosedraw-custom-menu-link/:companyId/:locationId',
      component: <WinLooseCustomMenuLink />,
    },

    {
      type: 'collapse',
      key: 'servicem8trigger',
      route: '/servicem8/:app_id/:locationId/:companyId',
      component: <Servicem8trigger />,
    },
    {
      type: 'collapse',
      key: 'chargebee',
      route: '/chargebee/:locationId/:companyId',
      component: <Chargebee />,
    },
    {
      type: 'collapse',
      key: 'simpro',
      route: '/simpro/:app_id/:locationId/:companyId',
      component: <Simpro />,
    },
    {
      type: 'collapse',
      key: 'pdfgenerator',
      route: '/pdfgenerator/:app_id/:locationId/:companyId',
      component: <PdfGenerator />,
    },
    {
      type: 'collapse',
      key: 'zoom',
      route: '/zoom/:app_id/:locationId/:companyId',
      component: <Zoom />,
    },
    {
      type: 'collapse',
      key: 'drchrono',
      route: '/drchrono/:app_id/:locationId/:companyId',
      component: <DrChrono />,
    },
    {
      type: 'collapse',
      key: 'raindrop',
      route: '/raindrop/',
      component: <Raindrop />,
    },
    {
      type: 'collapse',
      key: 'green',
      route: '/green/:app_id/:locationId/:companyId',
      component: <Green />,
    },
    {
      type: 'collapse',
      key: 'copper',
      route: '/copper/:app_id/:locationId/:companyId',
      component: <Copper />,
    },
    {
      type: 'collapse',
      key: 'clickup',
      route: '/clickup-credentials/:app_id/:locationId/:companyId',
      component: <ClickupTriger />,
    },
    {
      type: 'collapse',
      key: 'spreadsheet',
      route: '/spreadsheet/:locationId/:companyId',
      component: <Spreadsheets />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/agencyapp/:app_id/:companyId',
      component: <AgencyApp />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/acquity/:app_id/:companyId',
      component: <Acquity />,
    },
    {
      type: 'collapse',
      key: 'calender',
      route: '/:locationId/calender/*',
      component: <Calender />,
    },
    {
      type: 'collapse',
      key: 'scratch',
      route: '/scratchcard/*',
      component: <Scratch />,
    },
    {
      type: 'collapse',
      key: 'integration',
      route: '/integrations/*',
      component: <Integration />,
    },
    {
      type: 'collapse',
      key: 'deeplink',
      route: '/deeplinks/*',
      component: <Deeplinks />,
    },
    {
      type: 'collapse',
      key: 'sharelink',
      route: '/sharelinks/*',
      component: <Sharelink />,
    },
    {
      type: 'collapse',
      key: 'qrcode',
      route: '/qrcode/*',
      component: <QRCode />,
    },

    {
      type: 'collapse',
      key: 'hotslot',
      route: '/hotslot',
      component: <HotSlot />,
    },
    {
      type: 'collapse',
      key: 'trello',
      route: '/trello/:app_id/:locationId/:companyId',
      component: <Trello />,
    },
    {
      type: 'collapse',
      key: 'woocommerce',
      route: '/woocommerce/:app_id/:locationId/:companyId',
      component: <Woocommerce />,
    },
    {
      type: 'collapse',
      key: 'mux',
      route: '/mux/:app_id/:locationId/:companyId',
      component: <Mux />,
    },
    {
      type: 'collapse',
      key: 'mailjet',
      route: '/mailjet/:app_id/:locationId/:companyId',
      component: <Mailjet />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/auth/*',
      component: <Authentication />,
      guard: {
        valid: !user.email,
        redirect: `/${brand.ghl?.location_id}/dashboard`,
      },
    },
    // {
    //   type      : 'collapse',
    //   key       : 'admin',
    //   route     : '/:location_id/*',
    //   component : <Admin />,
    //   guard     : {
    //     valid    : user.email && user.roles.includes(Roles.ADMIN),
    //     redirect : user.email ? '/agency' : '/auth/sign-in',
    //   }
    // },
  ]
}

export default useRoutes
