/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import AgencyApp from './agency_app'
import GHL from './ghl'
import Webinar from './webinar'
import Stripe from './stripe'
import Messagebird from './messagebird'
import WinLooseDraw from './winloosedraw'
import Spreadsheet from './spreadsheet'
import ServiceM8Trigger from './servicem8trigger'
import Zoom from './zoom'
import Drchrono from './drchrono'

import Acquity from './acquity'

const routes = [
  {
    type: 'collapse',
    key: 'ghl_associate',
    route: '/ghl/associate',
    component: <GHL />,
  },
  {
    type: 'collapse',
    key: 'webinar',
    route: '/webinar',
    component: <Webinar />,
  },
  {
    type: 'collapse',
    key: 'agencyapp',
    route: '/agency-app',
    component: <AgencyApp />,
  },
  {
    type: 'collapse',
    key: 'servicem8',
    route: '/servicem8',
    component: <ServiceM8Trigger />,
  },
  {
    type: 'collapse',
    key: 'zoom',
    route: '/zoom',
    component: <Zoom />,
  },
  {
    type: 'collapse',
    key: 'drchrono',
    route: '/drchrono',
    component: <Drchrono />,
  },

  {
    type: 'collapse',
    key: 'acquity',
    route: '/acquity',
    component: <Acquity />,
  },
  {
    type: 'collapse',
    key: 'stripe',
    route: '/stripe',
    component: <Stripe />,
  },
  {
    type: 'collapse',
    key: 'spreadsheet',
    route: '/spreadsheet',
    component: <Spreadsheet />,
  },
  {
    type: 'collapse',
    key: 'winloosedraw',
    route: '/winloosedraw',
    component: <WinLooseDraw />,
  },
  {
    type: 'collapse',
    key: 'messagebird',
    route: '/messagebird',
    component: <Messagebird />,
  },
]

export default routes
