import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useLocation, useParams } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { useAppServices } from 'hook/services'
import { Snackbar } from '@mui/material'
const GoHighLevel = () => {
  //

  //https://woo-thoughtfully-eclectic-daze.wpcomstaging.com/wp-admin/admin.php?page=wc-admin
  const [consumerKey, setConsumerKey] = useState('')
  const [consumerSecret, setConsumerSecret] = useState('')
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const { locationId, companyId, app_id } = useParams()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get('type')
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const [userData, setuserData] = useState({})

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const payload = {
      cred: {
        consumerKey,
        consumerSecret,
        url,
      },
      location_id: locationId, //kEyKMducTgZLJcLFgw27
      company_id: companyId, //6NKdTpPe08P8DR5Jg7k6
      app_id: app_id,

      connection_status: 'active',
      type: type,
    }
    console.log(payload)

    try {
      const response = await axios.post(
        // 'http://localhost:8084/v1/woocommerce/create-credentials',
        'https://marketplace.ghl.store/v1/woocommerce/create-credentials',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setState({ ...state, open: true })
      console.log(response.data)
    } catch (error) {
      console.error('Error connecting:', error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        // backgroundImage:
        //   'url("https://cdn.pixabay.com/photo/2016/09/05/15/07/concrete-1646788_1280.jpg")',
        // backgroundSize: 'cover',
      }}
    >
      <Card
        sx={{
          width: '42%',
          textAlign: 'center',
          padding: '2rem',
          borderRadius: '29px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <MDTypography variant="h4" gutterBottom mb={5}>
          Connect with Woocommerce
        </MDTypography>
        <MDBox component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            type="text"
            label="consumerKey"
            value={consumerKey}
            onChange={(e) => setConsumerKey(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />

          <TextField
            type="text"
            label="consumerSecret"
            value={consumerSecret}
            onChange={(e) => setConsumerSecret(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />
          <TextField
            type="text"
            label="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            required
          />

          <MDButton
            style={{ fontSize: '0.9rem', textTransform: 'capitalize' }}
            type="submit"
            variant="contained"
            color="info"
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? 'Connecting...' : 'Connect'}
          </MDButton>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Connected Successfully"
            key={vertical + horizontal}
          />
        </MDBox>

        {/* <MDTypography variant="body2" sx={{ mt: 2 }}>
          By connecting your Woocommerce account, you authorize the integration with GHL.
        </MDTypography> */}
      </Card>
    </div>
  )
}

export default GoHighLevel
